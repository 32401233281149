@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;
}

.App {
  background: white;
  height: 100vh;
  text-align: center;
}

.root-container {
  height: 100%;
}

body {
  overflow: auto;
}


@import './styles/baseComponents/header.scss';
@import './styles/baseComponents/footer.scss';
@import './styles/baseComponents/Images.scss';
@import './styles/baseComponents/Logo.scss';
@import './styles/baseComponents/BackButton.scss';
@import './styles/baseComponents/Slideshow.scss';

@import './styles/pageComponents/Home.scss';
@import './styles/pageComponents/AboutUs.scss';
@import './styles/pageComponents/HinduFaith.scss';
@import './styles/pageComponents/Community.scss';
@import './styles/pageComponents/Hospitality.scss';
@import './styles/pageComponents/DisasterRelief.scss';
@import './styles/pageComponents/ContactUs.scss';
@import './styles/pageComponents/Donation.scss';
@import './styles/pageComponents/HealthFair2023.scss';
@import './styles/pageComponents/NewsLetter.scss';
@import './styles/pageComponents/HealthFair1.scss';
