// banner starts
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  min-height: 50vh !important;
}

.banner-inner {
  padding: 10rem 0 0 0;
  display: flex;
  flex-direction: column;
  text-align: start;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
}

//   banner ends

// section
.section {
  position: relative;
  width: 100%;
  margin: 60px 0;
  padding: 60px 0;
  background-color: #F9F9F9;
  overflow: hidden;
}

.sidebar-widget .widget-title {
  position: relative;
  padding-left: 45px;
  text-align: start;
  margin-bottom: 3px;
  font-size: 22px;
  color: #db4242 !important;
  font-weight: 600;
}

.widget-recent-posts .sigma_recent-post+.sigma_recent-post {
  padding-top: 20px;
  border-top: 1px solid #efefef;
}

.widget-recent-posts .sigma_recent-post+.sigma_recent-post {
  margin-top: 0px;
  margin-bottom: 5px;
}

.widget-recent-posts .sigma_recent-post {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.sidebar-widget .widget-title::before {
  content: '';
  width: 20px;
  height: 3px;
  border-radius: 4px;
  background-color: #7E4555;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.sidebar-widget .widget-title::before {
  content: '';
  width: 20px;
  height: 3px;
  border-radius: 4px;
  background-color: #7E4555;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.section-shaped {
  position: relative;
  overflow: hidden;
}

.section-lg {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.section {
  position: relative;
  padding-top: 4rem;
  padding-bottom: 4rem;
  height: 100%;
}

.information-background {
background-image: url();
  background-repeat: no-repeat;
  background-size: cover;
}

.sigma_post {
  position: relative;
  margin-bottom: 30px;
}

.sigma_post-thumb,
.gallery-thumb {
  position: relative;
  display: block;
  overflow: hidden;
}



/* holi */
.sigma_service {
  display: block;
  background-color: #fff;
  border: 1px solid #efefef;
  padding: 25px;
  text-align: left;
  border-radius: 10px;
  overflow: hidden;
  transition: .3s;
  margin-bottom: 30px;
}

.text-white {
  font-family: "Poppins", sans-serif;
  line-height: 1.2;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 10px;
}

.text-white-para {
  font-family: "Poppins", sans-serif;
  color: #ffffff;
  margin-bottom: 15px;
}

.sigma_service.border .sigma_service-thumb {
  position: relative;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  margin: 0 auto 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #efefef;
}

.sigma_service.border .sigma_service-thumb::before {
  content: '';
  position: absolute;
  top: 10px;
  right: 17px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #7E4555;
  transition: .3s;
}

.sigma_service.border .sigma_service-thumb::after {
  content: '';
  position: absolute;
  bottom: 10px;
  left: 17px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #7E4555;
  transition: .3s;
}

.sigma_service.primary-bg:hover,
.sigma_service.primary-bg {
  background-color: #7E4555;
  border: 0;
  width: 14rem;
  height: 22rem;
}

.sigma_service.secondary-bg:hover,
.sigma_service.secondary-bg {
  background-color: #db4242;
  border: 0;
  width: 14rem;
  height: 22rem;
}

.sigma_service.border.primary-bg .sigma_service-thumb::after,
.sigma_service.border.primary-bg .sigma_service-thumb::before,
.sigma_service.border.secondary-bg .sigma_service-thumb::after,
.sigma_service.border.secondary-bg .sigma_service-thumb::before {
  background-color: #fff;
}

.sigma_service.border:hover .sigma_service-thumb::after {
  bottom: -4px;
  left: 47px;
}

.sigma_service.border:hover .sigma_service-thumb::before {
  top: -4px;
  right: 47px;
}

.sigma_service.bg-white {
  border: 0;
}

.sigma_service.style-1.bg-white:hover .btn-link,
.sigma_service.style-1.bg-white:hover p,
.sigma_service.style-1.bg-white:hover h5 {
  color: #db4242;
}

.sigma_service.style-1.bg-white:hover i {
  color: #7E4555;
}

.sigma_service i {
  font-size: 60px;
  color: #7E4555;
  line-height: 1;
  transition: .3s;
}

.sigma_service .btn-link {
  margin-top: 20px;
  display: block;
}

.sigma_service .btn-link i {
  font-size: 14px;
  color: #db4242;
  margin-left: 5px;
}

.sigma_service-thumb {
  margin-bottom: 40px;
}

.sigma_service h5 {
  margin-bottom: 10px;
  transition: .3s;
}

.sigma_service p {
  margin: 0;
  transition: .3s;
}

.sigma_service:hover {
  background-color: #7E4555;
  transform: translateY(-3px);
}

.sigma_service.style-1:hover .btn-link,
.sigma_service.style-1:hover i,
.sigma_service.style-1:hover p,
.sigma_service.style-1:hover h5 {
  color: #fff;
}

/* Style 2 & 3 */
.sigma_service.style-3,
.sigma_service.style-2 {
  background-color: transparent;
  box-shadow: none;
  text-align: left;
  padding: 0;
}

.sigma_service.style-3 .sigma_service-thumb,
.sigma_service.style-2 .sigma_service-thumb {
  position: relative;
  margin: 0;
  z-index: 1;
}

.sigma_service.style-3 .sigma_service-thumb img,
.sigma_service.style-2 .sigma_service-thumb img {
  width: 100%;
}

.sigma_service.style-3 i,
.sigma_service.style-2 i {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -50%);
}

.sigma_service.style-3 .sigma_service-thumb::before,
.sigma_service.style-2 .sigma_service-thumb::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .4);
  transition: .3s;
  opacity: 0;
  visibility: hidden;
}

.sigma_service.style-3:hover i,
.sigma_service.style-3:hover .sigma_service-thumb::before,
.sigma_service.style-2:hover i,
.sigma_service.style-2:hover .sigma_service-thumb::before {
  opacity: 1;
  visibility: visible;
}

.sigma_service.style-3 h5,
.sigma_service.style-2 h5 {
  margin-bottom: 20px;
}

.sigma_service.style-3 p,
.sigma_service.style-2 p {
  margin: 0;
}

.sigma_service.style-3 .sigma_service-body,
.sigma_service.style-2 .sigma_service-body {
  padding: 30px;
}

.sigma_service.style-3 {
  border: 0;
}

.sigma_service.style-3 .sigma_service-body {
  position: relative;
  background-color: #fff;
  box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
  border: 0;
}

.sigma_service.style-3 .sigma_service-body::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #7E4555;
  transition: .3s;
}

.sigma_service.style-3:hover .sigma_service-body::before {
  width: 100%;
}

.sigma_service.style-2 .sigma_service-progress {
  margin-top: 20px;
}

.sigma_service.style-2 .sigma_service-progress .progress-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sigma_service.style-2 .sigma_service-progress .progress-content p {
  color: #db4242;
  font-size: 16px;
  font-weight: 500;
}

.sigma_service.style-2 .sigma_service-progress .sigma_progress {
  margin-top: 15px;
}

.sigma_service.style-2 .sigma_service-progress .progress {
  height: 8px;
  background-color: #efefef;
}

.sigma_service.style-2 .sigma_btn-custom {
  margin-top: 30px;
}


.subtitle {
  text-align: start;
  margin-bottom: 15px;
  font-size: 16px;
  text-transform: uppercase;
  color: #7E4555;
  font-weight: 700;
}

.image-card{
  cursor: pointer;
  width: 35rem;
  padding: 2rem;
  background-color: #fff;
  border: 3px solid #efefef;
}

.image-card-text{
  color: #db4242;
  font-weight: 600;
  font-size: 25px;
  line-height: 1.5;
  margin: 10px 0 0 0;
  font-family: 'Poppins', sans-serif;
}

.title-cards {
  position: relative;
  text-align: start;
  margin: 0;
  font-size: 46px;
  line-height: 1.2;
  margin-bottom: 50px;
  font-weight: 700;
  color: #ffffff;
}

.title-news {
  color: #db4242;
  font-weight: 600;
  line-height: 1.5;
  margin: 0 0 30px 0;
  font-family: 'Poppins', sans-serif;
  font-size: 50px;
  text-align: start;
}

.news-text {
  color: #db4242;
  font-weight: 600;
  line-height: 1.5;
  margin: 0 0 30px 0;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

.button-container {
  margin-bottom: 40px;
}

.red-button,
.voilet-button {
  display: table;
  cursor: pointer;
  color: white;
  font-size: 14px;
  padding: 20px 20px 20px 20px;
  transition: 0.3s;
  border-radius: 4px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.red-button {
  background-color: #db4242;
  margin-right: 10px;
  border: none;
}
.red-button:hover{
  border: 1px solid #db4242;
  color: #db4242;
  background: none;
}

.voilet-button {
  background-color: #7e4555;
  border: none;
}

.voilet-button:hover {
  border: 1px solid #7e4555;
  color: #7e4555;
  background: none;
}




