// banner starts
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .banner {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    min-height: 50vh !important;
  }
  
  .banner-news {
    padding: 10rem 0 0 0;
    display: flex;
    flex-direction: column;
    text-align: start;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
  }
  // banner ends
  
  .news-text {
    color: #db4242;
    font-weight: 600;
    line-height: 1.5;
    margin: 0 0 30px 0;
    font-family: 'Poppins', sans-serif;
    text-align: center;
  }
  