.image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .slideshow-container{
    margin-top: 30px;
    margin-bottom: 30px;
  }
.show-text{
  cursor: pointer;
  font-size: 14px;
    font-weight: 20px;
    color: #7E4555;
    text-decoration: none;
    transition: .3s;
    margin-top: 20px;
    margin-bottom: 30px;
}  
  
  .image-aline {
    margin: 1rem 1rem 1rem 1rem ;
    animation: fade-in-up 1s ease-out;
  }

.image-aline {
  transition: transform 0.3s ease; 
}

.image-aline:hover {
  transform: scale(1.5); 
}

.image-aline:hover {
  cursor: pointer;
}

  @keyframes fade-in-up {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
 