.btn-custom {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  position: relative;
  background-color: #7E4555;
  border: 0;
  font-size: 14px;
  text-transform: uppercase;
  padding: 15px 30px 41px 30px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border-radius: 30px;
  font-weight: 600;
  overflow: hidden;
  z-index: 1;
  font-family: 'Poppins', sans-serif;
}

.btn-custom:hover {
  border-radius: 30px;
  background-color: #d43308;
}


li {
  list-style: none;
}

a {
  text-decoration: none;
}
a:hover { color: #222; }

a img { border: none; }

.nav-text{
  font-family: 'Poppins', sans-serif;
  color: #444;
  font-weight: 700;
  font-size: 0.8125rem;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 10vh;
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: background-color 0.3s ease-in;
}

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 3rem;
  z-index: 50;
}

.nav-toggler {
  display: none;
}

.nav-toggler div {
  width: 2.5rem;
  height: 0.2rem;
  margin: 0.4rem;
  background: rgb(204, 204, 204);
  transition: 0.3s ease-in;
}

.nav-active .nav-menu {
  transform: translateX(0%);
}

.nav-toggler.toggle .line1 {
  transform: rotate(-45deg) translate(-4px, 5px);
}

.nav-toggler.toggle .line2 {
  opacity: 0;
}

.nav-toggler.toggle .line3 {
  transform: rotate(45deg) translate(-4px, -5px);
}

@media screen and (max-width: 768px) {
  .nav-toggler {
    display: block;
    cursor: pointer;
  }
  .nav-menu {
    position: fixed;
    top: 9vh;
    right: 0;
    height: 93vh;
    width: 50vw;
    background: #ffffff;
    flex-direction: column;
    transform: translateX(100%);
    transition: 0.5s ease-in;
    z-index: 50;
  }
}
