// banner starts
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;
}

.banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  min-height: 50vh !important;
}

.banner-about {
  padding: 10rem 0 0 0;
  display: flex;
  flex-direction: column;
  text-align:center;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
}
// banner ends


// articles starts
.entry-content {
  position: relative;
  padding: 40px 20px 40px 20px;

}

.title{
  font-family: 'Poppins', sans-serif;
  text-align: start;
    margin: 0;
    font-size: 25px;
    line-height: 1.2;
    margin-bottom: 15px;
    font-weight: 700;
    color: #db4242;
}

.subtitle{
  font-family: 'Poppins', sans-serif;
  text-align: start;
  margin-bottom: 15px;
  font-size: 16px;
  text-transform: uppercase;
  color: #7E4555;
  font-weight: 700;
}

.blockquote {
  font-family: 'Poppins', sans-serif;
  position: relative;
  text-align: start;
  margin: 20px 0;
  font-size: 14px;
  color: #020202;
  padding: 10px 30px;
  background-position: center;
  background-size: cover;
  border-left: 2px solid #7E4555;
}

A.blockquote:before,
p.blockquote:after{
 content: "";
 position: absolute;
 left: -9px;
 top: 50%;
 transform: translateY(-50%);
 width: 1px;
 height: 25px;
 background-color: #7E4555;
}

p.blockquote:after{
  left: 7px;
}

.image{
  width: 300px;
}