.container-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 150px;
    padding-right: 150px;
}
.footer-box {
    position: static;
    background-color: #7e4555;
    box-shadow: 0px -1px 8px rgba(132, 130, 130, 0.25);
    bottom: 0;
    z-index: 1;
    width: 100%;
    padding: 30px 0;
}
.footer-text{
    font-weight: 600;
    font-size: 15px;
    color: #fff;
}


  @media (max-width: 991px) {
    .container-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
      }
      
      .footer-box {
        position: static;
        background-color: #7e4555;
        z-index: 1;
        width: 100%;
        padding: 20px 0;
      }
      
      .footer-text {
        font-weight: 600;
        font-size: 15px;
        color: #fff;
        padding: 10px;
      }
      
  }
  