// banner starts
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  min-height: 50vh !important;
}

.banner-healthfair {
  padding: 10rem 0 0 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
}


.header-text {
  color: #db4242;
  font-weight: 600;
  line-height: 1.5;
  font-family: 'Poppins', sans-serif;
  margin: 0px 50px 0 50px;
}

.para-text {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 1.9;
  color: #777;
  margin: 0px 50px 0 50px;
}

.ant-timeline .ant-timeline-item-head-blue {
  border-color: #db4242;
  height: 20px;
  width: 20px;

}

.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-inline-start: -8px;
}

.ant-timeline .ant-timeline-item-tail {
  position: absolute;
  inset-block-start: 10px;
  inset-inline-start: 4px;
  height: 20rem;
  border-inline-start: 2px solid rgba(5, 5, 5, 0.06);
}

@media (max-width: 767px) {
  .header-text {
    margin-bottom: 3rem;
    margin: 0 20px;
    font-size: 18px;
  }

  .para-text {
    margin: 0 20px;
    font-size: 12px;
  }

  .ant-timeline.ant-timeline-label .ant-timeline-item-head {
    margin-inline-start: -12px;
    height: 12px;
    width: 12px;
  }

  .ant-timeline .ant-timeline-item-tail {
    position: static;
    height: auto;
    border: none;
  }
}
