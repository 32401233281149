// banner starts
.container{
  padding-left: 150px;
  padding-right: 150px;
}

.banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  min-height: 50vh !important;
}

.banner-hospitality {
  padding: 10rem 0 0 0;
  display: flex;
  flex-direction: column;
  text-align:center;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
}




.slider {
  position: relative;
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  overflow: hidden;
  padding: 30px 20px 30px 20px;
}

.slides {
  display: flex;
  transition: transform 0.5s;
}

.slide {
  flex: 0 0 100%;
}

.slide img {
  width: 100%;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
  transition: color 0.3s;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  background-color: #db4242;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.prev:hover,
.next:hover {
  color: #ffffff;
  background-color: #7E4555;
}