
#logo img {
	-webkit-transition: height .4s ease, padding .4s ease, margin .4s ease, opacity .3s ease;
	transition: height .4s ease, padding .4s ease, margin .4s ease, opacity .3s ease;
}

#logo img {
	max-height: 100%;
}

#logo {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	-ms-flex-align: center;
	max-height: 100%;
	padding: 10px 40px 10px 100px;
}

#logo a.standard-logo {
	display: -ms-flexbox;
	display: flex;
}

#logo a {
	color: #000;
	font-family: 'Poppins', sans-serif;
	font-size: 36px;
}

a {
	text-decoration: none !important;
}

#logo img {
	display: block;
	max-width: 100%;
	max-height: 100%;
	height: 100px;
}
