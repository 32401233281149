.container {
  padding-left: 150px;
  padding-right: 150px;
}

.banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  min-height: 50vh !important;
}

.banner-donation {
  padding: 10rem 0 0 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
}

.btn-custom {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  position: relative;
  background-color: #7E4555;
  border: 0;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  padding: 15px 30px 41px 30px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border-radius: 30px;
  font-weight: 600;
  overflow: hidden;
  z-index: 1;
}

.btn-custom:hover {
  border-radius: 30px;
  background-color: #d43308;
  color: #fff;
}

.form-text {
  line-height: 1.2;
  color: #db4242;
  font-weight: 700;
  font-size: 15px;
}

.sigma_donation-form .input-group {
  max-width: 370px;
}

.sigma_donation-form .input-group .sigma_btn-custom.btn-sm {
  font-size: 24px;
  line-height: 1.5;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.sigma_donation-form .input-group .sigma_btn-custom.btn-sm:before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.sigma_donation-form .sigma_select-amount {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 0;
}

.sigma_donation-form .sigma_select-amount li {
  display: flex;
  align-items: start;
  justify-content: start;
  padding: 8px 22px;
  border: 2px solid #efefef;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.3s;
}

.sigma_donation-form .sigma_select-amount li:hover,
.sigma_donation-form .sigma_select-amount li.active {
  background-color: #7e4555;
  color: #fff;
  border-color: #7e4555;
}

.sigma_donation-form .sigma_select-amount li+li {
  margin-left: 15px;
}


.form-group {
  position: relative;
  margin-bottom: 20px;
  text-align: start;
}

.form-group i {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: #e8e8e8;
}

.input-group-prepend,
.input-group-append {
  margin: 0;
  display: flex;
}

.input-group-prepend,
.input-group-prepend {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.form-control,
.input-group>.form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.form-control {
  border-radius: 3px;
}

.form-control {
  height: 60px;
  padding: 8px 15px;
  border-radius: 10px;
  border: 2px solid #efefef;
  width: 100%;
  color: #6e6e6e;
  background-color: #fff;
  letter-spacing: 0.1px;
  font-size: 14px;
}

.form-control-btn {
  height: 60px;
  margin-top: 15px;
  padding: 8px 15px;
  border-radius: 10px;
  border: 2px solid #efefef;
  width: 80px;
  color: #ffffff;
  background-color: #7e4555;
  letter-spacing: 0.1px;
  font-size: 14px;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #ffffff;
  border: none;
  transition: none;
}