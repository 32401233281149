// banner starts
.container{
    padding-left: 150px;
    padding-right: 150px;
  }
 
  .banner {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    z-index: 1;
    min-height: 50vh !important;
  }
  .banner-contact {
    padding: 10rem 0 0 0;
    display: flex;
    flex-direction: column;
    text-align:center;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
  }

.btn-custom {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  position: relative;
  background-color: #7E4555;
  border: 0;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  padding: 15px 30px 41px 30px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border-radius: 30px;
  font-weight: 600;
  overflow: hidden;
  z-index: 1;
}

.btn-custom:hover {
  border-radius: 30px;
  background-color: #d43308;
color: #fff;
}


.sigma_box.box-lg {
  padding: 40px;
}

.sigma_box {
  padding: 30px;
  margin: 50px 0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 10px 50px 0px rgba(53,82,99,0.09);
}

.form-control {
  height: 60px;
  padding: 8px 15px;
  border-radius: 10px;
  border: 2px solid #efefef;
  width: 100%;
  color: #ededed;
  background-color: #fff;
  letter-spacing: 0.1px;
  font-size: 14px;
  margin-top: 15px;
}
.middle-card-text{
font-size: 23px;
margin-top: 10px;
margin-bottom: 10px;
}
