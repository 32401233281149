.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;
}

.banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  min-height: 50vh !important;
}

.banner-community {
  padding: 10rem 0 0 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #ffffff;
}

.sigma_block-box,
.sigma_icon-block {
  padding: 20px;
  position: relative;
  margin-bottom: 30px;
  background-color: #fff;
  border-radius: 0;
  z-index: 1;
  box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
  transition: .3s;
}

.sigma_icon-block i {
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  line-height: 1;
  margin: 0 0 15px;
  color: #777;
  display: inline-block;
}

.sigma_icon-block.has-link:hover {
  background-color: #f7f7f7;
}

.sigma_icon-block a {
  display: block;
}

.sigma_icon-block a h5 {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.sigma_icon-block h5 {
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  margin-bottom: 0;
}

.sigma_icon-block h5+p {
  margin-top: 10px;
}

.sigma_icon-block p {
  margin-bottom: 0;
}

.sigma_icon-block svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* Style 2 & 3 */

.sigma_icon-block.icon-block-2,
.sigma_icon-block.icon-block-3 {
  display: flex;
  align-items: center;
  padding: 15px;
  box-shadow: none;
  padding: 0;
}

.sigma_icon-block.icon-block-2 {
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
}

.sigma_icon-block.icon-block-2 i,
.sigma_icon-block.icon-block-3 i {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  font-size: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7E4555;
  margin-right: 20px;
}

.sigma_icon-block.icon-block-2 i {
  font-family: 'Poppins', sans-serif;
  font-size: 70px;
}

.sigma_icon-block.icon-block-3 img {
  margin-right: 20px;
  max-width: 100px;
}

.sigma_icon-block.icon-block-2 .sigma_icon-block-content i {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  margin: 0;
  display: inline-block;
  margin-top: 20px;
  color: #777;
  opacity: .3;

}

.sigma_icon-block.icon-block-2 h5+p,
.sigma_icon-block.icon-block-3 h5+p {
  margin-top: 5px;
}

.sigma_icon-block.icon-block-2 .sigma_icon-block-content,
.sigma_icon-block.icon-block-3 .sigma_icon-block-content {
  flex: 1;
}

/* Style 4 */
.sigma_icon-block.icon-block-4 {
  border: 0;
  background-color: transparent;
  box-shadow: none;
  display: flex;
  align-items: flex-start;
  padding: 0;
}

.sigma_icon-block.icon-block-4 i {
  font-family: 'Poppins', sans-serif;
  position: relative;
  margin-right: 15px;
  margin-bottom: 0;
  font-size: 34px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #7E4555;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7E4555;
}

.sigma_icon-block.icon-block-4 i::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
  width: 1px;
  background: linear-gradient(to bottom, #7E4555, transparent);
}

.sigma_icon-block.icon-block-4 .sigma_icon-block-content {
  flex: 1;
}

/* Style 5 */
.sigma_icon-block.icon-block-5 {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  text-align: center;
}

.sigma_icon-block.icon-block-5 .icon-wrapper {
  padding: 60px 20px;
  position: relative;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 35px;
  color: #7E4555;
  z-index: 1;
  transition: .3s;
  display: block;
  text-align: center;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, .06);
}

.sigma_icon-block.icon-block-5 .icon-wrapper i {
  font-family: 'Poppins', sans-serif;
  font-size: 50px;
}

.sigma_icon-block.icon-block-5 .icon-wrapper:hover {
  background-color: #db4242;
}

.sigma_icon-block.icon-block-5 .icon-wrapper:hover i {
  color: #fff;
}

.sigma_icon-block.icon-block-5 h5 {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  color: #777;
  max-width: 180px;
  margin: 0 auto;
}


/* Style 6 and 7 */
.sigma_icon-block.icon-block-6,
.sigma_icon-block.icon-block-7 {
  text-align: center;
  background-color: transparent;
  box-shadow: none;
}

.sigma_icon-block.icon-block-6 i,
.sigma_icon-block.icon-block-7 i {
  font-family: 'Poppins', sans-serif;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 auto 20px;
  font-size: 50px;
  border: 3px solid #7E4555;
  color: #7E4555;
}

.sigma_icon-block.icon-block-6 h5,
.sigma_icon-block.icon-block-7 h5 {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
}

.sigma_icon-block.icon-block-6 .count,
.sigma_icon-block.icon-block-7 .count {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 120px;
  opacity: .2;
  line-height: 1;
  z-index: -1;
}

.sigma_icon-block.icon-block-7 {
  box-shadow: none;
  border: 0;
  text-align: center;
  padding: 20px 40px 20px 40px;
  margin-top: 20px;
  background-color: #282828;
  border-radius: 10px;
  max-width: 300px;
}

.sigma_icon-block.icon-block-7 i {
  width: auto;
  height: auto;
  font-size: 60px;
  display: inline-flex;
  justify-content: flex-start;
  background-color: transparent;
  border: 0;
}

.sigma_icon-block.icon-block-7>i {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 140px;
  opacity: .1;
  color: #fff;
}

.sigma_icon-block.icon-block-7 p,
.sigma_icon-block.icon-block-7 h5 {
  color: #fff;
}

.sigma_icon-block.icon-block-7 .sigma_search-adv-input {
  margin-top: 20px;
}

.sigma_icon-block.icon-block-7 .form-control {
  background-color: #db4242;
}

.sigma_icon-block.icon-block-7 button i {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #fff;
  margin: 0;
}

.sigma_icon-block.icon-block-7.text-center {
  max-width: 100%;
}

.sigma_icon-block.icon-block-7.text-center i {
  margin: 0;
  margin-top: 20px;
}

.sigma_icon-block.icon-block-7.text-center>i {
  margin: 0;
  top: 50%;
  left: 50%;
  right: auto;
  color: #db4242;
  transition: .3s;
  opacity: 0;
  visibility: hidden;
  transform: translate(-50%, -50%);
}

.sigma_icon-block.icon-block-7.text-center:hover {
  box-shadow: 0px 10px 50px 0px rgba(53, 82, 99, 0.09);
  border-color: transparent;
}

.sigma_icon-block.icon-block-7.text-center:hover>i {
  opacity: .1;
  visibility: visible;
}

.sigma_icon-block.icon-block-7.text-center .sigma_icon-block-content>span {
  font-family: 'Poppins', sans-serif;
  color: #7E4555;
  font-weight: 600;
  margin-bottom: 10px;
  display: block;
  text-transform: uppercase;
}

.sigma_icon-block.icon-block-7.text-center span i {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  margin-left: 5px;
}

.sigma_icon-block.icon-block-7.light {
  background-color: #fff;
  border: 2px solid #efefef;
}

.sigma_icon-block.icon-block-7.light h5 {
  color: #db4242;
}

.sigma_icon-block.icon-block-7.light p {
  color: #767e88;
}

/* Style 8 */
.sigma_icon-block.icon-block-8 {
  background-color: transparent;
  padding: 0;
  display: flex;
  align-items: center;
}

.sigma_icon-block.icon-block-8 .icon-wrapper {
  margin-right: 10px;
}

.sigma_icon-block.icon-block-8 h6 {
  color: #fff;
  margin: 0;
  font-size: 14px;
}

.sigma_icon-block.icon-block-8 .icon-wrapper i {
  opacity: .3;
}

.sigma_icon-block.icon-block-8 .icon-wrapper i,
.sigma_icon-block.icon-block-8 .sigma_rating,
.sigma_icon-block.icon-block-8 .sigma_rating i {
  margin: 0;
}

.sigma_icon-block.icon-block-8 span {
  font-weight: 600;
}




/* Funeral Help start */
.read-more-target,
.read-more-trigger_opened {
  display: none;
}

.read-more-state:checked~.read-more-wrap .read-more-target,
.read-more-state:checked~.read-more-trigger_opened {
  display: block;
}

.read-more-state:checked~.read-more-trigger_closed {
  display: none;
}

/* Funeral Help end */

/* Medicare start */
.read-more-target1,
.read-more-trigger_opened1 {
  display: none;
}

.read-more-state1:checked~.read-more-wrap1 .read-more-target1,
.read-more-state1:checked~.read-more-trigger_opened1 {
  display: block;
}

.read-more-state1:checked~.read-more-trigger_closed1 {
  display: none;
}

/* Medicare end */

/* Tax start */
.read-more-target2,
.read-more-trigger_opened2 {
  display: none;
}

.read-more-state2:checked~.read-more-wrap2 .read-more-target2,
.read-more-state2:checked~.read-more-trigger_opened2 {
  display: block;
}

.read-more-state2:checked~.read-more-trigger_closed2 {
  display: none;
}

/* Tax end */

/* Disaster start */
.read-more-target3,
.read-more-trigger_opened3 {
  display: none;
}

.read-more-state3:checked~.read-more-wrap3 .read-more-target3,
.read-more-state3:checked~.read-more-trigger_opened3 {
  display: block;
}

.read-more-state3:checked~.read-more-trigger_closed3 {
  display: none;
}

.mail-text {
  font-size: 14px;
  font-weight: 20px;
  color: #7E4555;
  text-decoration: none;
  transition: .3s;
}

.card-adjustment{
  margin: 0 1rem 0 2rem;
}