.slide-show{
    display: flex;
    flex-wrap: wrap;
}

.image-slideshow{
width: 100%;
box-sizing: border-box;
padding: 20px 25px 20px 25px;
margin-top: 35px;
margin-bottom: 35px;
}